import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    {() => (
      <React.Fragment>
        <SEO title="404: Not found" />
        <div className="fixed pin bg-special-red z-50">
          <div className="flex justify-center h-full">
            <div className="max-w-md px-6 self-center">
              <h1 className="big-stat-text text-white mb-8">404!</h1>
              <p className="highlight-text text-white mb-6">
                Sorry but the page you're looking for does not exist :(
              </p>
              <div className="text-ash-black stat-heading-text text-lg no-whitespace">
                <a
                  href="https://palomagroup.com"
                  className="text-special-red no-underline cursor-pointer inline-block bg-white hover:bg-ash-black p-2"
                >
                  Go to Paloma Group
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )}
  </Layout>
);
